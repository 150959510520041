import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/remote-config';

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
    apiKey: "AIzaSyDIRz4-oWfkhfuvns-G_W0sbXJpNWimn8M",
    authDomain: "fixturelist-2023.firebaseapp.com", 
    projectId: "fixturelist-2023",
    storageBucket: "fixturelist-2023.appspot.com",
    messagingSenderId: "471469376607",
    appId: "1:471469376607:web:634af5f4bc60ccb20707ea",
    measurementId: "G-TT17JVLDS0"
};

const app = firebase.initializeApp(firebaseConfig);
const auth = app.auth();
// const remoteConfig = firebase.remoteConfig();

// remoteConfig.settings.minimumFetchIntervalMillis = 300000;

// remoteConfig.defaultConfig = {
//     "team_field_setup_color": "text-red-500",
//     "committee_field_setup_color": "text-green-500",
// };

// export {remoteConfig}; 

export {firebaseConfig}

export default app;